import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/features/breakpoint/contexts/breakpoint-context.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/features/providers/color-scheme-provider/color-scheme-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/features/providers/server-group-client-provider/server-group-client-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/features/providers/site-providers/site-providers.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/lib/react-query/provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/lib/styled-jsx/registry.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/lib/theme/theme-provider.tsx");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/styles/index.scss");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/styles/events/survey/index.scss");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/styles/events/reward/index.scss");
import(/* webpackMode: "eager" */ "/app/apps/galaxy-web/src/styles/events/reward-survey/index.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0_gr5qociamtnfnjveefygainege/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0_gr5qociamtnfnjveefygainege/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.3_next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0_gr5qociamtnfnjveefygainege/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/jotai@2.8.0_@types+react@18.2.48_react@18.2.0/node_modules/jotai/esm/react.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-intl@3.14.1_next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18_odyaa4d7rpadtufbqqayhiu6aa/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Maven_Pro\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"mavenPro\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.3_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.75.0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Maven_Pro\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"Bai_Jamjuree\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/normalize.css@8.0.1/node_modules/normalize.css/normalize.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-inlinesvg@4.1.3_react@18.2.0/node_modules/react-inlinesvg/dist/index.mjs")